import _ from "lodash";
import {convertCamelCaseToSpaceDelimited} from "../commonUtilities";
import {isArrayNullOrEmpty} from "./arrayUtilities";

export function isObjectNullOrEmpty(obj) {
	return !(obj && Object.getOwnPropertyNames(obj).length !== 0);
}

export function objectHasProperty(object, property) {
	return !isObjectNullOrEmpty(object) && !!Object.prototype.hasOwnProperty.call(object, property);
}

export function deepCopyObject(object) {
	if (!object) return {};
	return JSON.parse(JSON.stringify(object));
}

export function createObjectFromArray(array) {
	let object = {};

	if (isArrayNullOrEmpty(array)) return object;

	array.map((item) => {
		return (object = {...object, ...{[item]: item}});
	});

	return object;
}

export function createObjectFromObjectPropertyNames(object) {
	let result = {};
	for (let property in object) {
		if (objectHasProperty(object, property)) {
			result = {...result, [property]: convertCamelCaseToSpaceDelimited(property)};
		}
	}
	return result;
}

export function getPropertyName(object, value) {
	let listPropertyNames = Object.keys(object);
	return listPropertyNames.find((f) => object[f] === value);
}

export function getPropertyWithId(object, value) {
	const listPropertyNames = Object.keys(object);
	const propertyName = listPropertyNames.find((f) => object[f].id === value);
	return !propertyName ? null : object[propertyName];
}

export function getPropertyValue(object, property) {
	return objectHasProperty(object, property) ? object[property] : undefined;
}

export function compareObjects(object1, object2){
	return _.isEqual(object1,object2);
}