import * as config from "../../SiteConfig/siteConstants";

export const reportSearchModel = (pageId, siteGroupId, startDate, endDate, reportType, courseId, creditType, regionId, quarterNumber, userInfo, districtName, categoryId) => {
    return {
        siteGroupId: siteGroupId || config.APH_SITE_GROUP_ID,
        pageId: pageId.toString() || "",
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        reportType: reportType ? reportType : "",
        categoryId: categoryId || 0,
        courseId: courseId || 0,
        creditType: creditType ? creditType : "",
        userInfo: userInfo ?? "",
        regionId: regionId || 0,
        districtName: districtName || "",
        quarterNumber: quarterNumber || "",
        ClearCriteria: clearCriteria,
        ValidateModel: validateModel,
        RequiresCourseSelection: requiresCourseSelection
    };
};

function clearCriteria() {
    this.startDate = null;
    this.endDate = null;
    this.reportType = "";
    this.courseId = 0;
    this.categoryId = 0;
    this.creditType = "";
    this.regionId = 0;
    this.districtName = "";
    this.quarterNumber = "";
    this.userInfo = "";
}

function validateModel() {
    return this.startDate
        && this.endDate
        && this.reportType
        && this.siteGroupId > 0
        && ( !requiresCourseSelection(this.reportType) || (this.courseId > 0) );
}

function requiresCourseSelection(reportType = this.reportType){
    return reportType === "DownloadFollowUpActivities"
        || reportType === "AssessmentData"
        || reportType === "AllAssessmentData"
        || reportType === "CykData"
        || reportType === "AllCykData"
        || reportType === "CourseProgression"
        || reportType === "ExternalCourseCykData"
        || reportType === "ExternalCourseCykDataNoDisplayIsCorrect"
        || reportType === "ModuleProgressData";
}

export const createReportPrintObject = (
    name,
    component) =>
    ({
        name,
        component
    });