import PropTypes from "prop-types";
import React from "react";
import userApi from "../../../Containers/User/userApi";
import * as storageUtilities from "../../../Utilities/LocalStorage/storageUtilities";
import {createEmptyLink, locations} from "../../../Utilities/Location";
import {GridCell, gridConstants, GridContainer} from "../../Grid";
import {LinkButton} from "../../Link";
import {createMenuItem, ProfileMenu} from "../../ProfileMenu";
import {getHeaderNavigation, isHeaderNavHidden} from "./headerNavUtility";

const HeaderNav = ({
                       fetchUser,
                       handleApiCall,
                       isAdmin,
                       isLoggedIn,
                       path,
                       redirect,
                       setIsAdmin,
                       siteDetails,
                       user: {firstName, lastName},
                   }) => {
    if (!siteDetails) return null;

    const profileLabel = `${firstName} ${lastName}`;
    const navLink = getHeaderNavigation(siteDetails, path, isLoggedIn, isAdmin);

    if (isHeaderNavHidden(navLink)) return null;

    const returnAsAdminSuccess = (data) => {
        storageUtilities.removeAdminRefreshToken();
        storageUtilities.persistLogin(data.userId, data.accessToken, data.refreshToken, data.isAdmin, data.roleId);
        setIsAdmin(data.isAdmin);
        fetchUser();
        redirect(locations.USER_SEARCH.path);
    }

    const handleReturnAsAdmin = () => {
        handleApiCall(() => userApi.getUserTokenFromRefreshToken(storageUtilities.getAdminRefreshToken()), returnAsAdminSuccess);
    }

    const pathLink = navLink ? navLink
        : createEmptyLink();

    const menuItems = [
        createMenuItem(`Update Profile`, () => redirect(locations.USER_PROFILE.path)),
        createMenuItem(`Sign out`, () => redirect(locations.SIGN_OUT.path)),
    ];

    if (storageUtilities.getRoleIdOfImpersonator())
        menuItems.unshift(createMenuItem(`Finished Impersonating?`, handleReturnAsAdmin));

    return (
        <nav className="headerNav">
            <GridContainer hasMarginX>
                <GridCell small_columns={gridConstants.column.SIX}>
                    {
                        isLoggedIn && firstName &&
                        <ProfileMenu menuItems={menuItems} profileLabel={profileLabel}/>
                    }
                </GridCell>
                <GridCell small_columns={gridConstants.column.SIX}>
                    <LinkButton {...pathLink} />
                </GridCell>
            </GridContainer>
        </nav>
    );
};

HeaderNav.propTypes = {
    fetchUser: PropTypes.func.isRequired,
    handleApiCall: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    path: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    siteDetails: PropTypes.object,
    setIsAdmin: PropTypes.func.isRequired,
    user: PropTypes.object,
};

export default React.memo(HeaderNav);
